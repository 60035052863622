import React from "react";
import MapsComponent from "../../../components/maps";
import RRenderer from "../../../components/richtextRenderer";

import "./index.scss";

const LocationTile = ({ mapTileTitle, mapTileAddress, mapTileLocation }) => {
  return (
    <div className="tile grid sm:grid-cols-2 lg:grid-cols-1 mx-auto rounded-lg overflow-hidden">
      <div className="pt-8 border border-b-0 sm:border-r-0 lg:border-r sm:border-b lg:border-b-0 border-text-area sm:rounded-l-lg lg:rounded-l-none rounded-t-lg sm:rounded-t-none lg:rounded-t-lg">
        <h5 className="font-normal px-7.5 text-center">{mapTileTitle}</h5>
        <div className="px-7.5 mt-5 mb-6">
          {
            <RRenderer
              data={mapTileAddress}
              config={{
                p: "font-normal",
              }}
            />
          }
        </div>
      </div>
      <div className="size-map self-end w-full">
        <MapsComponent location={mapTileLocation} />
      </div>
    </div>
  );
};
const Locations = ({
  locationsTitle,
  mapTile1Title,
  mapTile1Address,
  mapTile1Location,
  mapTile2Title,
  mapTile2Address,
  mapTile2Location,
  mapTile3Title,
  mapTile3Address,
  mapTile3Location,
  className,
}) => {
  return (
    <div className="w-full pt-16 md:pt-12 px-9 md:px-10 xl:px-0">
      <h3 className="text-secondary text-left uppercase mb-4 md:mb-6">{locationsTitle}</h3>
      <div className={`w-full grid lg:grid-cols-3 gap-y-10 lg:gap-y-0 md:gap-x-6 ${className}`}>
        <LocationTile
          mapTileTitle={mapTile1Title}
          mapTileAddress={mapTile1Address}
          mapTileLocation={mapTile1Location}
        />
        <LocationTile
          mapTileTitle={mapTile2Title}
          mapTileAddress={mapTile2Address}
          mapTileLocation={mapTile2Location}
        />
        <LocationTile
          mapTileTitle={mapTile3Title}
          mapTileAddress={mapTile3Address}
          mapTileLocation={mapTile3Location}
        />
      </div>
    </div>
  );
};
export default Locations;
