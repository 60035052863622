import React from "react";
import RRenderer from "../../../components/richtextRenderer";

import "./index.scss";

const DirectInquiries = ({ directTitle, directContactBottom, directImage }) => {
  return (
    <div className="inquiries-container flex-shrink-0 bg-direct-inquiries px-5 py-6 rounded-lg">
      <h5 className="pt-40px text-primary text-center md:text-left mb-6">{directTitle}</h5>
      <img className="w-full h-full mb-12" src={directImage.file.url} alt={directImage.title} />
      <div>
        <RRenderer data={directContactBottom} />
      </div>
    </div>
  );
};

export default DirectInquiries;
