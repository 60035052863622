import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import HeroWithImage from "../components/heroWithImage/index";
import Seo from "../components/seo";
import Locations from "../containers/contact-us/location";
import RRenderer from "../components/richtextRenderer";
import ContactUsForm from "../containers/contact-us/contact-us-form";
import DirectInquiries from "../containers/contact-us/directInquiries";
import ContactUsLayout from "../containers/contact-us/contact-us-layout";
import CorporateDirectory from "../containers/contact-us/corporate-directory";

import heroDesktop from "../assets/heroDesktop/ContactUs.png";
import heroMobile from "../assets/heroMobile/ContactUs.png";

const ContactUsPage = ({ data }) => {
  const intl = useIntl();
  const {
    welcomeTitle,
    welcomeContentRichText,
    locationsTitle,
    mapTile1Title,
    mapTile1Address,
    mapTile1Location,
    mapTile2Title,
    mapTile2Address,
    mapTile2Location,
    mapTile3Title,
    mapTile3Address,
    mapTile3Location,
    directTitle,
    directImage,
    directContactTop,
    directContactBottom,
    nameErrorMessage,
    companyErrorMessage,
    phoneErrorMatchesMessage,
    phoneErrorMinMessage,
    emailErrorMessage,
    commentTextareaLabel,
    commentErrorMessage,
    requiredErrorMessage,
    buttonLabel,
    corporateTitle,
  } = data.contactUsPage.nodes[0];
  return (
    <Layout inverted>
      <Seo title="CONTACT US" />
      <HeroWithImage
        title={intl.locale === "fr" ? "NOUS CONTACTER" : "CONTACT US"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <ContactUsLayout>
        <div className="block md:flex md:items-start pt-6 px-9 md:px-10 xl:px-0">
          <div className="md:mr-16 lg:mr-24 xl:mr-40 mb-12 md:mb-0">
            <div className="my-8 md:my-0">
              <h3 className="text-secondary text-left uppercase mb-4 md:mb-6">{welcomeTitle}</h3>
              <div className="mb-6">
                <RRenderer data={welcomeContentRichText} />
              </div>
            </div>
            <ContactUsForm
              nameErrorMessage={nameErrorMessage}
              companyErrorMessage={companyErrorMessage}
              phoneErrorMatchesMessage={phoneErrorMatchesMessage}
              phoneErrorMinMessage={phoneErrorMinMessage}
              emailErrorMessage={emailErrorMessage}
              commentTextareaLabel={commentTextareaLabel}
              commentErrorMessage={commentErrorMessage}
              requiredErrorMessage={requiredErrorMessage}
              directContactTop={directContactTop}
              buttonLabel={buttonLabel}
            />
          </div>
          <DirectInquiries
            directTitle={directTitle}
            directContactBottom={directContactBottom}
            directImage={directImage}
          />
        </div>
        <Locations
          locationsTitle={locationsTitle}
          mapTile1Title={mapTile1Title}
          mapTile1Address={mapTile1Address}
          mapTile1Location={mapTile1Location}
          mapTile2Title={mapTile2Title}
          mapTile2Address={mapTile2Address}
          mapTile2Location={mapTile2Location}
          mapTile3Title={mapTile3Title}
          mapTile3Address={mapTile3Address}
          mapTile3Location={mapTile3Location}
          className="contact-us-map-area"
        />
        <div className="w-full px-9 md:px-10 xl:px-0">
          <hr className="w-full my-12 border-secondary" />
        </div>
        <CorporateDirectory corporateTitle={corporateTitle} data={data.offices.edges} />
      </ContactUsLayout>
    </Layout>
  );
};

export default ContactUsPage;

export const query = graphql`
  query ContactUs($locale: String) {
    contactUsPage: allContentfulContactUsPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        welcomeTitle
        welcomeContentRichText {
          raw
        }
        locationsTitle
        mapTile1Title
        mapTile1Address {
          raw
        }
        mapTile1Location {
          lat
          lon
        }
        mapTile2Title
        mapTile2Address {
          raw
        }
        mapTile2Location {
          lat
          lon
        }
        mapTile3Title
        mapTile3Address {
          raw
        }
        mapTile3Location {
          lat
          lon
        }
        directTitle
        directImage {
          title
          file {
            url
          }
        }
        directContactTop {
          raw
        }
        directContactBottom {
          raw
        }
        nameErrorMessage
        companyErrorMessage
        phoneErrorMatchesMessage
        phoneErrorMinMessage
        emailErrorMessage
        commentTextareaLabel
        commentErrorMessage
        requiredErrorMessage
        buttonLabel
        corporateTitle
      }
    }
    offices: allContentfulCorporateDirectoryOffices(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: name }
    ) {
      edges {
        node {
          address {
            raw
          }
          name
          subtitle
          # email - removed for present view
          phone
          phone2
          websiteUrl
        }
      }
    }
  }
`;
