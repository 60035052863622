import React from "react";
import { Link } from "gatsby";

import RRenderer from "../../../components/richtextRenderer";

const CorporateDirectory = ({ data, corporateTitle }) => {
  return (
    <div className="w-full px-9 md:px-10 xl:px-0">
      <h3 className="text-secondary text-left uppercase mb-4 md:mb-6">{corporateTitle}</h3>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-10">
        {data &&
          data.map(({ node: { email, name, phone, phone2, address, subtitle, websiteUrl } }) => (
            <div className="">
              <div className="mb-5">
                <h5 className="mb-2 text-primary">{name}</h5>
                {subtitle && <span>{subtitle}</span>}
              </div>
              {address && (
                <ul className="mb-5">
                  <RRenderer data={address} />
                </ul>
              )}
              {(email || phone || phone2 || websiteUrl) && (
                <div className="">
                  {email && (
                    <div className="flex">
                      <span className="font-semibold">e:&nbsp;</span>
                      <span>{email}</span>
                    </div>
                  )}
                  {phone && (
                    <div className="flex">
                      <span className="font-semibold">t:&nbsp;</span>
                      <span>{phone}</span>
                    </div>
                  )}
                  {phone2 && (
                    <div className="flex">
                      <span className="font-semibold">f:&nbsp;</span>
                      <span>{phone2}</span>
                    </div>
                  )}
                  {websiteUrl && (
                    <div className="flex">
                      <Link to={websiteUrl} className="underline text-secondary">
                        {websiteUrl}
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CorporateDirectory;
